

const About = () => {
    return <div>
        <h6>Mtaa ya Sheng</h6>

        <p>Nothing generates a good chuckle like Mchongoano. Ahh the good old' days ...</p>

        <p>Wacha tukupeleke na rieng..</p>
    </div>
}

export default About